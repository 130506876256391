import { React, useEffect, useRef, useState } from "react";
import "./contactus.scss";
import { useWindowSize } from "react-use";
import Commonbanner from "../../Common Banner/CommonBanner";
import { IoIosArrowDown } from "react-icons/io";
import Footer from "../../Footer/Footer";
import FormHelperText from "@mui/material/FormHelperText";
import {
  contactCall,
  contactLocation,
  contactMail,
  contactMap,
  images,
  contactLinkedin,
  overviewBanner,
  contactCar,
  contactPlane,
  contactTrain,
  contactArrow,
  submitButton,
  contactLocationGold,
} from "../../assets";
import { Link } from "react-router-dom";
import { Breadcrumbs, Select, MenuItem, NativeSelect } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { motion } from "framer-motion";
import { textVariants } from "../../Header/Motion";
import LocomotiveScroll from "locomotive-scroll";
import PreLoader from "../../loader/preloader";
import Joi from "joi";
import config from "../../config/config";
import http from "../../helpers/http";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    background: "rgba(0, 0, 0, 0.83)",
    color: "#f5b071",
  },
  input: {
    color: "#f5b071",
    backgroundColor: "rgba(0, 0, 0, 0.83)",
    opacity: 1,
    "&:focus": {
      borderRadius: 4,
      borderColor: "rgba(255,255,255,0.2)",
      boxShadow: "0 0 0 0.2rem rgba(0,190,255,0.6)",
      background: "rgba(0,0,0,0)",
    },
  },
}));

const Contactus = () => {
  const classes = useStyles();
  const { width } = useWindowSize();
  const scrollRef = useRef(null);
  const [rotate, setRotate] = useState();

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    country_code: "+91",
    phone: "",
    company: "",
    industry: " ",
    country: " ",
    state: " ",
    city: " ",
    zip: "",
    message: "",
    consent: false,
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});

  const industries = [
    "Automobile",
    "Power Plants",
    "Aerospace",
    "Defence",
    "Oil & Gas",
    "Ship Building & repairs",
    "Heat Exchangers / Condensers",
    "Evapourator / Chiller",
    "Desalination plants",
    "Sugar & Chemical ",
    "Other",
  ];

  const locations = [
    {
      city: "Jaipur",
      plane: "252 Km",
      train: "3 Hour 20 mins",
      car: "4 Hour (Approx.)",
    },
    {
      city: "Mumbai",
      plane: "900 km",
      train: "12 Hour 30 mins (Approx.)",
      car: "17 Hour (Approx.)",
    },
    {
      city: "Delhi",
      plane: "472 km",
      train: "5 hrs (approx.)",
      car: "7 Hour 30 mins (Approx.)",
    },
  ];

  // const [countryCode, setcountryCode] = useState("+91");

  let base_url = config.api_url + "/forms/contact";
  let sizeLimit = 2;

  const navigate = useNavigate();

  const schema = Joi.object({
    first_name: Joi.string().trim().required().label("First Name"),
    last_name: Joi.string().trim().required().label("Last Name"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email"),
    phone: Joi.number().required().label("Contact Number"),
    company: Joi.string().trim().required().label("Company"),
    // industry: Joi.string().trim().required().label("industry"),
    country: Joi.string().trim().required().label("Country"),
    state: Joi.string().trim().required().label("State"),
    city: Joi.string().trim().required().label("City"),
    // zip: Joi.string().trim().label("Zip"),
    message: Joi.string().trim().label("Message"),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = schema.validate(data, {
      abortEarly: false,
      allowUnknown: true,
      errors: {
        wrap: {
          label: "",
        },
      },
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (e) => {
    console.log("form submit");

    e.preventDefault();
    // console.log(values);
    let errors = await validate(values);
    setFormError(errors);

    console.log({ errors });
    if (Object.keys(errors).length) {
      return;
    }

    setLoading(true);

    // console.log(...formData);
    // return;

    const { data } = await http.post(base_url, values);

    if (data) {
      setValues({
        first_name: "",
        last_name: "",
        email: "",
        country_code: "+91",
        phone: "",
        company: "",
        industry: "",
        country: "",
        state: "",
        city: "",
        zip: "",
        message: "",
      });
      navigate("/thank-you");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    if (e.target.type == "checkbox") {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.checked,
        };
      });
    } else {
      setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    }
  };

  const [age, setAge] = useState("");

  const handleChange1 = (event) => {
    setAge(event.target.value);
  };

  const fetchCodes = async () => {
    const data = await http.get(`${config.api_url}/general/countries/codes`);
    // console.log(data);
    if (data) {
      setCodes(data.data);
    }
  };
  const fetchCountires = async () => {
    const data = await http.get(`${config.api_url}/general/countries`);
    // console.log(data);
    if (data) {
      setCountries(data.data);
    }
  };
  const fetchStates = async (country_id) => {
    const data = await http.get(
      `${config.api_url}/general/states/${country_id}`
    );
    // console.log(data);
    if (data) {
      setStates(data.data);
    }
  };
  const fetchCities = async (state_id) => {
    const data = await http.get(`${config.api_url}/general/cities/${state_id}`);
    // console.log(data);
    if (data) {
      setCities(data.data);
    }
  };

  const [selectedIndustry, setSelectedIndustry] = useState("Industry*");

  const handleIndustry = (event) => {
    // Update the selected country code when the user makes a selection
    setSelectedIndustry(event.target.value);
  };

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);

      scroll.destroy();
    };
  }, []);

  useEffect(() => {
    fetchCodes();
    fetchCountires();
    setSelectedLocation(locations[0]);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = ["india", "america", "more"];

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${images.contact_bg.image})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <section
          id="scrolltop"
          className="contact_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div className="contact_bg">
            <div className="gallery_breadcrumbs contact_breadcrumbs">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link to="/contact-us">CONTACT US</Link>
              </Breadcrumbs>
            </div>
            <div className="contact_heading">
              <motion.h1
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                CONTACT US
              </motion.h1>
            </div>
            <div
              className="scroll-down-d contact_scroll"
              style={{ zIndex: "5" }}
            >
              <div className="spinner scroll-down">
                {" "}
                <a
                  style={{ cursor: "pointer" }}
                  href="#find_us"
                  data-scroll-to
                  className="animate"
                ></a>{" "}
              </div>
            </div>
          </div>
        </section>

        <section className="contact_sec2" id="find_us" data-scroll-section>
          <div className="contact_row ">
            <motion.div
              className="contact_col"
              variants={textVariants("left", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="address_section">
                <h2 className="heading">WHERE CAN YOU FIND US</h2>
                <div className="address_container">
                  <div className="address_wrapper">
                    <div className="content_img">
                      <img
                        src={contactLocation}
                        alt="map"
                        className="location_img"
                      />
                    </div>
                    <div className="content_wrapper">
                      {/* <p className="sub_heading">Our Office Address</p> */}
                      <p className="sub_heading">Office And Factory Address</p>
                      <a
                        // href="https://www.google.com/maps?ll=25.154669,75.865143&z=15&t=m&hl=en&gl=IN&mapclient=embed&q=Multimetals+Kota+Industrial+Area+Kota,+Rajasthan+324007"
                        href="https://maps.app.goo.gl/hVH54JkiNJQtV1FL6"
                        target="main"
                        className="address"
                      >
                        Multimetals Limited, Heavy Industries Area, Kansua Road,
                        Kota-324003, Rajasthan, India
                      </a>
                    </div>
                  </div>
                  {/* <div className="address_wrapper">
                    <div className="content_img">
                      <img
                        src={contactLinkedin}
                        alt="map"
                        className="linkedin_img"
                      />
                    </div>
                    <div className="content_wrapper">
                      <p className="sub_heading">Write To Us</p>
                      <a
                        href="https://in.linkedin.com/in/nitin-agrawal-a0438913"
                        className="linkedin_link"
                        target="main"
                      >
                        Nitin Agrawal{" "}
                      </a>
                      <a
                        href="https://in.linkedin.com/in/priyanka-agrawal-94818615"
                        className="linkedin_link"
                        target="main"
                      >
                        Priyanka Agrawal{" "}
                      </a>
                    </div>
                  </div> */}
                  <div className="address_wrapper2">
                    <h4 className="contact-heading">Contact Us</h4>
                    <div className="contact-inner-bx">
                      <div style={{ marginRight: "5em" }}>
                        {/* <h6 className="sub_headings">{"> "}Export</h6> */}
                        <h6 className="sub_headings">
                          {"> "}Export Sales & Marketing
                        </h6>
                        <div className="address_wrapper">
                          <div className="content_img">
                            <img
                              src={contactCall}
                              alt="map"
                              className="phone_img"
                            />
                          </div>
                          <div className="content_wrapper">
                            <a href="tel:+917447118568" className="address">
                              +91-7447118568{" "}
                            </a>
                          </div>
                        </div>
                        <div className="address_wrapper">
                          <div className="content_img">
                            <img
                              src={contactMail}
                              alt="map"
                              className="phone_img"
                            />
                          </div>
                          <div className="content_wrapper">
                            <a
                              href="mailto:priyanka@multimetals.in"
                              className="address"
                            >
                              priyanka@multimetals.in{" "}
                            </a>
                            <br />
                            <a
                              href="mailto:sales@multimetals.in"
                              className="address"
                            >
                              sales@multimetals.in{" "}
                            </a>
                          </div>
                        </div>

                        <div className="contact-subinner-bx">
                          <h6 className="sub_headings">{"> "}Purchase</h6>
                          <div className="address_wrapper">
                            <div className="content_img">
                              <img
                                src={contactCall}
                                alt="map"
                                className="phone_img"
                              />
                            </div>
                            <div className="content_wrapper">
                              <a href="tel:+917447118506" className="address">
                                +91-7447118506{" "}
                              </a>
                              <br />
                              <a href="tel:+919928038880" className="address">
                                +91-9928038880{" "}
                              </a>
                            </div>
                          </div>
                          <div className="address_wrapper">
                            <div className="content_img">
                              <img
                                src={contactMail}
                                alt="map"
                                className="phone_img"
                              />
                            </div>
                            <div className="content_wrapper">
                              <a
                                href="mailto:purchase@multimetals.in"
                                className="address"
                              >
                                purchase@multimetals.in{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* <h6 className="sub_headings">{"> "}Domestic</h6> */}
                        <h6 className="sub_headings">
                          {"> "}Domestic Sales & Marketing
                        </h6>
                        <div className="address_wrapper">
                          <div className="content_img">
                            <img
                              src={contactCall}
                              alt="map"
                              className="phone_img"
                            />
                          </div>
                          <div className="content_wrapper">
                            <a href="tel:+919829104300" className="address">
                              +91-9829104300{" "}
                            </a>
                          </div>
                        </div>
                        <div className="address_wrapper">
                          <div className="content_img">
                            <img
                              src={contactMail}
                              alt="map"
                              className="phone_img"
                            />
                          </div>
                          <div className="content_wrapper">
                            <a
                              href="mailto:hngupta@multimetals.in"
                              className="address"
                            >
                              hngupta@multimetals.in{" "}
                            </a>
                            <br />
                            <a
                              href="mailto:info@multimetals.in"
                              className="address"
                            >
                              info@multimetals.in{" "}
                            </a>
                          </div>
                        </div>

                        <div className="contact-subinner-bx">
                          <h6 className="sub_headings">{"> "}HR</h6>
                          <div className="address_wrapper">
                            <div className="content_img">
                              <img
                                src={contactCall}
                                alt="map"
                                className="phone_img"
                              />
                            </div>
                            <div className="content_wrapper">
                              <a href="tel:+917665436108" className="address">
                                +91-7665436108
                              </a>
                            </div>
                          </div>
                          <div className="address_wrapper">
                            <div className="content_img">
                              <img
                                src={contactMail}
                                alt="map"
                                className="phone_img"
                              />
                            </div>
                            <div className="content_wrapper">
                              <a
                                href="mailto:hr@multimetals.in"
                                className="address"
                              >
                                hr@multimetals.in{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="address_wrapper">
                    <div className="content_img">
                      <img src={contactCall} alt="map" className="phone_img" />
                    </div>
                    <div className="content_wrapper">
                      <p className="sub_heading">Contact Us</p>
                      <a href="tel:+917442798550" className="address">
                        +91-744-2798550{" "}
                      </a>
                    </div>
                  </div>

                  <div className="address_wrapper">
                    <div className="content_img">
                      <img src={contactMail} alt="map" className="mail_img" />
                    </div>

                    <div className="content_wrapper">
                      <p className="sub_heading">Write To Us</p>
                      <a
                        href="mailto:contact@multimetals.in"
                        className="address"
                      >
                        contact@multimetals.in{" "}
                      </a>
                    </div>
                  </div> */}

                 {/* <div className="career-text">
                    <p>
                      <span>{">"} </span>
                      <Link to="/career">Career</Link>
                    </p>
                  </div> */}
                </div>
              </div>
            </motion.div>
            <motion.div
              className="contact_col"
              variants={textVariants("right", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <iframe
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.3955765529486!2d75.86505267533643!3d25.15611757773744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396f853a81e81bc9%3A0xff069184b175b44a!2sMultimetals%2C%20Kota%20Industrial%20Area%2C%20Kota%2C%20Rajasthan%20324007!5e0!3m2!1sen!2sin!4v1690193830076!5m2!1sen!2sin"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.351250672381!2d75.8648667752101!3d25.15761497773642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396f9ad582e53cab%3A0x7347255cf881ae3d!2sMultimetals%20Limited!5e0!3m2!1sen!2sin!4v1706079799350!5m2!1sen!2sin"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
              {/* <img src={contactMap} alt="map" className="map_img" /> */}
            </motion.div>
          </div>
          <div className="travel-row">
            <motion.div
              className="travel-distance"
              variants={textVariants("left", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="heading japiur-drop">
                DISTANCE FROM
                <FormControl fullWidth sx={{ display: "inline" }}>
                  <Select
                    className="select-input-jaipur"
                    defaultValue="Jaipur"
                    inputProps={{
                      id: "uncontrolled-native",
                    }}
                    sx={{
                      font: "normal normal 2vw Secular One",
                      lineHeight: "0vw",
                      minHeight: "0",

                      ".MuiOutlinedInput-input": {
                        color: "var(--text-color)",
                        minHeight: "0",
                        borderBottom: "0",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        color: "#fff",
                        border: "none",
                        outline: "none",
                        padding: "0",
                        minHeight: "0",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "0",
                        minHeight: "0",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "var(--text-color) !important",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        marginRight: "5px",
                      },
                      "@media (max-width: 600px)": {
                        font: "normal normal 5vw Secular One",
                        lineHeight: "0vw",
                        minHeight: "0",
                        border: "none",
                        ".MuiOutlinedInput-input": {
                          color: "var(--text-color)",
                          minHeight: "0",
                          borderBottom: "0",
                        },
                      },
                    }}
                    onChange={(e) => {
                      setSelectedLocation(
                        _.find(locations, { city: e.target.value })
                      );
                    }}
                  >
                    {locations?.map((item, i) => {
                      return (
                        <MenuItem value={item.city} key={i}>
                          {item.city}
                        </MenuItem>
                      );
                    })}
                    {/* <MenuItem value={10}>MUMBAI</MenuItem>
                    <MenuItem value={20}>DELHI</MenuItem>
                    <MenuItem value={30}>JAIPUR</MenuItem> */}
                  </Select>
                </FormControl>
                - KOTA
                <br />
                <p className="nearest-airport">
                  The Nearest Airport to Manufacturing unit is in Jaipur
                </p>
              </h2>
            </motion.div>
            <motion.div
              className="travel-duration"
              variants={textVariants("right", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="travel-type">
                <img src={contactLocationGold} alt="plane" className="plane" />
                <span>{selectedLocation?.plane}</span>
              </div>
              <div className="travel-type">
                <img src={contactTrain} alt="train" className="train" />
                <span>{selectedLocation?.train}</span>
              </div>
              <div className="travel-type">
                <img src={contactCar} alt="car" className="car" />
                <span>{selectedLocation?.car}</span>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="contact_sec3" data-scroll-section>
          <div className="my_container">
            <motion.h2
              variants={textVariants("left", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              CONTACT FORM
            </motion.h2>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="contact_form_row row">
                <div className="input_wrapper col-lg-6">
                  <div className="input_container">
                    <input
                      type="text"
                      className="form_input1"
                      placeholder="First name*"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="error_message">{formError.first_name}</p>
                </div>

                <div className="input_wrapper col-lg-6">
                  <div className="input_container">
                    <input
                      type="text"
                      className="form_input1"
                      placeholder="Last name*"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="error_message">{formError.last_name}</p>
                </div>

                <div className="input_wrapper col-lg-6">
                  <div className="input_container">
                    <input
                      type="email"
                      className="form_input1"
                      placeholder="Email Id*"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="error_message">{formError.email}</p>
                </div>
                <div className="input_wrapper col-lg-6">
                  <div className="input_container4">
                    <FormControl className="select1">
                      <Select
                        className="select-section"
                        sx={{
                          color: "#fff",
                          ".MuiOutlinedInput-input": {
                            // padding: ".75em 2.031em",
                            color: "#fff",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "0",
                            color: "#fff",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderRadius: "0",
                            border: "0",
                          },
                          ".MuiSelect-icon": {
                            display: "none",
                          },
                        }}
                        name="country_code"
                        value={values.country_code}
                        onChange={handleChange}
                      >
                        {codes.map((item, i) => {
                          return (
                            <MenuItem value={`+${item.phone_code}`} key={i}>
                              {" "}
                              {`+${item.phone_code}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <img
                        src={contactArrow}
                        alt="dropdown"
                        className="dropdown1"
                      />
                    </FormControl>
                    <input
                      type="tel"
                      className="form_input1"
                      placeholder="Contact number*"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="error_message">{formError.phone}</p>
                </div>
                <div className="input_wrapper col-lg-6">
                  <div className="input_container">
                    <input
                      type="text"
                      className="form_input1"
                      placeholder="Company*"
                      name="company"
                      value={values.company}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="error_message">{formError.company}</p>
                </div>

                <div className="input_wrapper col-lg-6">
                  {/* <FormControl className="input_container" fullWidth>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#FFFFFF",
                        // fontSize: "15px",
                        paddingLeft: "0",
                        fontFamily: "Poppins",
                        fontWeight: "300",
                        
                        zIndex: "1",
                        ".MuiOutlinedInput-input": {
                          padding: ".75em 2.031em",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        // ".MuiSvgIcon-root ": {
                        //   fill: "#FFC295 !important",
                        //   float: "right !important",
                        // },
                      }}
                      // name={values.industry}
                      // value={values.industry}
                      value={selectedIndustry}
                      onChange={handleIndustry}
                    >
                      <MenuItem value=" " disabled>Industry*</MenuItem>
                      {industries?.map((item, i) => {
                        return (
                          <MenuItem value={item} key={i}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <img
                      src={contactArrow}
                      alt="dropdown"
                      className="dropdown"
                    />
                  </FormControl> */}
                  <FormControl
                    className="inp_container"
                    sx={{ m: 1, border: "#fff" }}
                    fullWidth
                  >
                    <Select
                      MenuProps={{
                        classes: {
                          paper: classes.paper,
                        },
                      }}
                      inputProps={{
                        classes: {
                          root: classes.input,
                        },
                      }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#FFFFFF",
                        paddingLeft: "0",
                        fontFamily: "Poppins",
                        fontWeight: "300",

                        zIndex: "1",
                        ".MuiOutlinedInput-input": {
                          padding: ".75em 2.031em",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        // ".MuiSvgIcon-root ": {
                        //   fill: "#FFC295 !important",
                        //   float: "right !important",
                        // },
                      }}
                      name="industry"
                      value={values.industry}
                      onChange={handleChange}
                      displayEmpty
                      // inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value=" ">Industry*</MenuItem>
                      {industries?.map((item, i) => {
                        return (
                          <MenuItem value={item} key={i}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <img
                      src={contactArrow}
                      alt="dropdown"
                      className="dropdown"
                    />
                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                  <p className="error_message">{formError.industry}</p>
                </div>
                <div className="input_wrapper col-lg-6">
                  <FormControl
                    className="inp_container"
                    sx={{ m: 1, border: "#fff" }}
                    fullWidth
                  >
                    <Select
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#FFFFFF",
                        paddingLeft: "0",
                        fontFamily: "Poppins",
                        fontWeight: "300",

                        zIndex: "1",
                        ".MuiOutlinedInput-input": {
                          padding: ".75em 2.031em",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        // ".MuiSvgIcon-root ": {
                        //   fill: "#FFC295 !important",
                        //   float: "right !important",
                        // },
                      }}
                      name="country"
                      value={values.country}
                      onChange={(e, selected) => {
                        handleChange(e);
                        fetchStates(selected.props["country-id"]);
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value=" ">Country</MenuItem>
                      {countries?.map((item, i) => {
                        return (
                          <MenuItem
                            value={item.name}
                            key={i}
                            country-id={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <img
                      src={contactArrow}
                      alt="dropdown"
                      className="dropdown"
                    />
                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                  {/* <FormControl className="input_container" fullWidth>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#FFFFFF",
                        // fontSize: "17px",
                        paddingLeft: "0",
                        // textTransform: "uppercase",
                        fontFamily: "Poppins",
                        fontWeight: "300",
                        
                        zIndex: "1",
                        ".MuiOutlinedInput-input": {
                          padding: ".75em 2.031em",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        // ".MuiSvgIcon-root ": {
                        //   fill: "#FFC295 !important",
                        //   float: "right !important",
                        // },
                      }}
                      // native={true}
                      name="country"
                      value={values.country}
                      onChange={(e, selected) => {
                        handleChange(e);
                        fetchStates(selected.props["country-id"]);
                      }}
                      defaultValue=""
                    >
                      <MenuItem value=" " disabled>
                        Country
                      </MenuItem>
                      {countries?.map((item, i) => {
                        return (
                          <MenuItem
                            value={item.name}
                            key={i}
                            country-id={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <img
                      src={contactArrow}
                      alt="dropdown"
                      className="dropdown"
                    />
                  </FormControl> */}
                  <p className="error_message">{formError.country}</p>
                </div>
                <div className="input_wrapper col-lg-6">
                  {/* <FormControl className="input_container" fullWidth>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#FFFFFF",
                        fontSize: "17px",
                        paddingLeft: "0",
                        // textTransform: "lowercase",
                        fontFamily: "Poppins",
                        fontWeight: "300",
                        
                        zIndex: "1",
                        ".MuiOutlinedInput-input": {
                          padding: ".75em 2.031em",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        // ".MuiSvgIcon-root ": {
                        //   fill: "#FFC295 !important",
                        //   float: "right !important",
                        // },
                      }}
                      name="state"
                      value={values.state}
                      onChange={(e, selected) => {
                        handleChange(e);
                        fetchCities(selected.props["state-id"]);
                      }}
                    >
                      <MenuItem value=" " disabled>
                        State
                      </MenuItem>
                      {states?.map((item, i) => {
                        return (
                          <MenuItem
                            value={item.name}
                            key={i}
                            state-id={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <img
                      src={contactArrow}
                      alt="dropdown"
                      className="dropdown"
                    />
                  </FormControl> */}
                  <FormControl
                    className="inp_container"
                    sx={{ m: 1, border: "#fff" }}
                    fullWidth
                  >
                    <Select
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#FFFFFF",
                        paddingLeft: "0",
                        fontFamily: "Poppins",
                        fontWeight: "300",

                        zIndex: "1",
                        ".MuiOutlinedInput-input": {
                          padding: ".75em 2.031em",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                      }}
                      name="state"
                      value={values.state}
                      onChange={(e, selected) => {
                        handleChange(e);
                        fetchCities(selected.props["state-id"]);
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value=" ">State</MenuItem>
                      {states?.map((item, i) => {
                        return (
                          <MenuItem
                            value={item.name}
                            key={i}
                            state-id={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <img
                      src={contactArrow}
                      alt="dropdown"
                      className="dropdown"
                    />
                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                  <p className="error_message">{formError.state}</p>
                </div>
                <div className="input_wrapper col-lg-6">
                  {/* <FormControl className="input_container" fullWidth>
                    <Select
                      MenuProps={{ disableScrollLock: true }}
                      inputProps={{ "aria-label": "Without label" }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#FFFFFF",
                        fontSize: "17px",
                        paddingLeft: "0",
                        // textTransform: "uppercase",
                        fontFamily: "Poppins",
                        fontWeight: "300",
                        
                        zIndex: "1",
                        ".MuiOutlinedInput-input": {
                          padding: ".75em 2.031em",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        // ".MuiSvgIcon-root ": {
                        //   fill: "#FFC295 !important",
                        //   float: "right !important",
                        // },
                      }}
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                    >
                      <MenuItem value=" " disabled>
                        City
                      </MenuItem>
                      {cities?.map((item, i) => {
                        return (
                          <MenuItem value={item.name} key={i}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <img
                      src={contactArrow}
                      alt="dropdown"
                      className="dropdown"
                    />
                  </FormControl> */}
                  <FormControl
                    className="inp_container"
                    sx={{ m: 1, border: "#fff" }}
                    fullWidth
                  >
                    <Select
                      sx={{
                        width: "100%",
                        height: "100%",
                        color: "#FFFFFF",
                        paddingLeft: "0",
                        fontFamily: "Poppins",
                        fontWeight: "300",

                        zIndex: "1",
                        ".MuiOutlinedInput-input": {
                          padding: ".75em 2.031em",
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "0",
                        },
                      }}
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value=" ">City</MenuItem>
                      {cities?.map((item, i) => {
                        return (
                          <MenuItem value={item.name} key={i}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <img
                      src={contactArrow}
                      alt="dropdown"
                      className="dropdown"
                    />
                    {/* <FormHelperText>Without label</FormHelperText> */}
                  </FormControl>
                  <p className="error_message">{formError.city}</p>
                </div>

                <div className="input_wrapper col-lg-6">
                  <div className="input_container">
                    <input
                      type="text"
                      className="form_input1"
                      placeholder="Zip / Postal Code"
                      name="zip"
                      value={values.zip}
                      onChange={handleChange}
                    />
                  </div>
                  <p className="error_message">{formError.zip}</p>
                </div>
                <div className="input_wrapper col-lg-6">
                  <div className="input_container1">
                    <textarea
                      placeholder="Message/ Cover letter"
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                  {/* <p className="error_message">{formError.message}</p> */}
                </div>
                <div className="input_wrapper submit_wrapper col-lg-6">
                  <div className="input_container2">
                    <div className="checkbox">
                      {/* <div className="input">
                        <input
                          type="checkbox"
                          checked={values.consent}
                          onChange={handleChange}
                          name="consent"
                          className="consent_input"
                        />
                      </div> */}
                      <p>
                        Multimetals will use all information provided here
                        solely in accordance with the{" "}
                        <Link to="/privacy-policy">Privacy Policy.</Link>
                      </p>
                    </div>
                    <p className="error_message">{formError.consent}</p>
                  </div>
                  <div className="submit-button">
                    <button type="submit">
                      {loading ? "Loading.." : "Submit"}{" "}
                      <img src={submitButton} alt="submitButton" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Contactus;
